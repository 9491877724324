import React from "react";

export function TwitterImg() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_212_7317)">
        <path
          d="M12.9375 2.96627C12.496 3.16227 12.0215 3.29427 11.5235 3.35377C12.032 3.04927 12.4225 2.56677 12.606 1.99177C12.1305 2.27377 11.6035 2.47877 11.0425 2.58927C10.594 2.11077 9.9535 1.81177 9.2455 1.81177C7.656 1.81177 6.488 3.29477 6.847 4.83427C4.8015 4.73177 2.9875 3.75177 1.773 2.26227C1.128 3.36877 1.4385 4.81627 2.5345 5.54927C2.1315 5.53627 1.7515 5.42577 1.42 5.24127C1.393 6.38177 2.2105 7.44877 3.3945 7.68627C3.048 7.78027 2.6685 7.80227 2.2825 7.72827C2.5955 8.70627 3.5045 9.41777 4.5825 9.43777C3.5475 10.2493 2.2435 10.6118 0.9375 10.4578C2.027 11.1563 3.3215 11.5638 4.7115 11.5638C9.2825 11.5638 11.865 7.70327 11.709 4.24077C12.19 3.89327 12.6075 3.45977 12.9375 2.96627Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_212_7317">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0.9375 0.687744)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
