export const colors = {
  alpha: "#D63C5E",
  bravo: "#E2E2E2",
  charlie: "#FFFFFF",
  delta: "#DEDEDE",
  echo: "#808080",
  fargo: "#E5E5E5",
  golf: "#C0C0C0",
  hotel: "#E3E3E3",
  igloo: "#FAFAFA",
  jaguar: "#EEEEEE",
  kangaroo: "#C7C7C7",
  lambo: "#B4B4B4",
  mango: "#EBEBEB",
  night: "#EAEAEA",
};
